import React, { Component } from 'react';
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }
    componentDidCatch(error, info) {       
        console.log(error, info);      
        this.setState({ hasError: true });
    }
    backToDashboard() {
        window.location.href = "/login";
    }
    render() {
        if (this.state.hasError) {
            return (<>
               <p>Has ERROR</p>
            </>);
        }
        return this.props.children;
    }
}
export default ErrorBoundary