import * as constants from './constant'
const INIT_STATE = {
    loading: false,
};
const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {        
        case constants.CHATS_Get:
        case constants.CHATS_Get_LIST:
        case constants.CHATS_DELETE:
        case constants.CHATS_EXISTENCE:
        case constants.CHATS_BLOCK_USER:
        case constants.CHATS_HAS_NEW:
            return { ...state,  loading: true, IsDelete: false, IsBlocked: false };
        case constants.CHATS_Get_FAILED:
        case constants.CHATS_Get_LIST_FAILED:
        case constants.CHATS_DELETE_FAILED:
            case constants.CHATS_EXISTENCE_FAILED:
            case constants.CHATS_BLOCK_USER_FAILED:
            case constants.CHATS_HAS_NEW_FAILED:
            return { ...state, loading: false, error: action.payload };       
        case constants.CHATS_HAS_NEW_SUCCESS:
            return { ...state, ListNewChats: action.payload, loading: false, error: null };           
        case constants.CHATS_Get_LIST_SUCCESS:
            return { ...state, ListChats: action.payload, loading: false, error: null };           
        case constants.CHATS_Get_SUCCESS:
            return { ...state, ChatsById: action.payload, loading: false, error: null };           
        case constants.CHATS_DELETE_SUCCESS:
            return { ...state, IsDelete: true, loading: false, error: null };           
        case constants.CHATS_EXISTENCE_SUCCESS:
            return { ...state, IsChatsExistence: action.payload, loading: false, error: null };           
        case constants.CHATS_BLOCK_USER_SUCCESS:
            return { ...state, IsBlocked: true, loading: false, error: null };           
        default:
            return { ...state };
    }
};
export default Reducer;
