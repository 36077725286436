import { all, call, fork, put, takeEvery} from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionDelete, optionGet, optionPost } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';
function* ChatsExistence({ payload: { body } }) {    
    try {
        const response = yield call(fetchJSON, listUrl.ChatExistence, optionGet(body));               
        yield put(action.chatsExistenceSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.chatsExistenceFailed(message));
    }
}
function* ChatsBlockUser({ payload: { body } }) { 
    try {
        const response = yield call(fetchJSON, listUrl.ChatBlockUser, optionPost(body));               
        yield put(action.ChatsBlockUserSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.ChatsBlockUserFailed(message));
    }
}
function* ChatsGetById({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.chatsGet, optionGet(body));   
        yield put(action.ChatsGetSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.ChatsGetFailed(message));
    }
}
function* ChatsGetList({ payload: { body } }) {    
    try {
        const response = yield call(fetchJSON, listUrl.chatsChatList, optionGet(body)); 
       // console.log(response)              
        yield put(action.ChatsGetListSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.ChatsGetListFailed(message));
    }
}
function* ChatsHasNew({ payload: { body } }) {  
      
    try {
        const response = yield call(fetchJSON, listUrl.ChatHasNewChat, optionGet(body)); 
        yield put(action.ChatsHasNewSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.ChatsHasNewFailed(message));
    }
}
function* ChatsDelete({ payload: { body } }) {  
    try {
        const response = yield call(fetchJSON, listUrl.chatsDelete, optionDelete(body));               
        yield put(action.chatsDeleteSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.chatsDeleteFailed(message));
    }
}
export function* watchChatsHasNew() {
    yield takeEvery(constants.CHATS_HAS_NEW, ChatsHasNew);
}
export function* watchChatsBlockUser() {
    yield takeEvery(constants.CHATS_BLOCK_USER, ChatsBlockUser);
}
export function* watchChatsExistence() {
    yield takeEvery(constants.CHATS_EXISTENCE, ChatsExistence);
}
export function* watchChatsDelete() {
    yield takeEvery(constants.CHATS_DELETE, ChatsDelete);
}
export function* watchChatsGetList() {
    yield takeEvery(constants.CHATS_Get_LIST, ChatsGetList);
}
export function* watchChatsGetById() {
    yield takeEvery(constants.CHATS_Get, ChatsGetById);
}
function* Saga() {
    yield all([
        fork(watchChatsHasNew),
        fork(watchChatsBlockUser),
        fork(watchChatsDelete),
        fork(watchChatsExistence),
        fork(watchChatsGetList),
        fork(watchChatsGetById),
    ]);
}
export default Saga;