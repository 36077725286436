export const CHATS_Get_LIST = 'CHATS_Get_LIST';
export const CHATS_Get_LIST_SUCCESS = 'CHATS_Get_LIST_SUCCESS';
export const CHATS_Get_LIST_FAILED = 'CHATS_Get_LIST_FAILED';


export const CHATS_Get = 'CHATS_Get';
export const CHATS_Get_SUCCESS = 'CHATS_Get_SUCCESS';
export const CHATS_Get_FAILED = 'CHATS_Get_FAILED';


export const CHATS_BLOCK_USER = 'CHATS_BLOCK_USER';
export const CHATS_BLOCK_USER_SUCCESS = 'CHATS_BLOCK_USER_SUCCESS';
export const CHATS_BLOCK_USER_FAILED = 'CHATS_BLOCK_USER_FAILED';

export const CHATS_DELETE = 'CHATS_DELETE';
export const CHATS_DELETE_SUCCESS = 'CHATS_DELETE_SUCCESS';
export const CHATS_DELETE_FAILED = 'CHATS_DELETE_FAILED';

export const CHATS_EXISTENCE = 'CHATS_EXISTENCE';
export const CHATS_EXISTENCE_SUCCESS = 'CHATS_EXISTENCE_SUCCESS';
export const CHATS_EXISTENCE_FAILED = 'CHATS_EXISTENCE_FAILED';


export const CHATS_HAS_NEW = 'CHATS_HAS_NEW';
export const CHATS_HAS_NEW_SUCCESS = 'CHATS_HAS_NEW_SUCCESS';
export const CHATS_HAS_NEW_FAILED = 'CHATS_HAS_NEW_FAILED';