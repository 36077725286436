import { jwtDecode } from "jwt-decode";
export const decodeToken = (token) => {
    try {
        const jwtToken = token;
        if (!jwtToken) { return null; }
        return jwtDecode(jwtToken);
    }
    catch {
        return null;
    }
}

export const isUserAuthenticated = () => {
    try {
        const jwtToken = getLoggedInUser();
        if (!jwtToken) { return false; }
        return true;
    }
    catch {
        return false;
    }
};
export const getLoggedInUser = () => {
    const user = localStorage.getItem("strayers_token");
    return user || null;
};
export const loading = () => {
    return (<div className="text-center"></div>)
}
export const listAge = () => {
    const list = []
    for (let i = 18; i <= 90; i++) {
        list.push(i)
    }
    return list;
}


export function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
const listCountry = [
    { name: 'Antigua and Barbuda', shortName: 'Antigua' },
    { name: 'Bolivia, Plurinational State of Bolivia', shortName: 'Bolivia' },
    { name: 'Bosnia and Herzegovina', shortName: 'Bosnia' },
    { name: 'British Indian Ocean Territory', shortName: 'Ocean Territory' },
    { name: 'Brunei Darussalam', shortName: 'Brunei Darussalam' },
    { name: 'Burkina Faso', shortName: 'Burkina Faso' },
    { name: 'Cayman Islands', shortName: 'Cayman Islands' },
    { name: 'Central African Republic', shortName: 'Central African' },
    { name: 'Christmas Island', shortName: 'Christmas Island' },
    { name: 'Cocos (Keeling) Islands', shortName: 'Cocos Islands' },
    { name: 'Congo, The Democratic Republic of the', shortName: 'Congo' },
    { name: 'Cook Islands', shortName: 'Cook Islands' },
    { name: 'Ivory Coast', shortName: 'Ivory Coast' },
    { name: 'Czech Republic', shortName: 'Czech' },
    { name: 'Dominican Republic', shortName: 'Dominican' },
    { name: 'El Salvador', shortName: 'El Salvador' },
    { name: 'Equatorial Guinea', shortName: 'Equatorial Guinea' },
    { name: 'Falkland Islands', shortName: 'Falkland Islands' },
    { name: 'Faroe Islands', shortName: 'Faroe Islands' },
    { name: 'French Polynesia', shortName: 'French Polynesia' },
    { name: 'French Southern and Antarctic Lands', shortName: 'French Southern' },
    { name: 'Heard Island and McDonald Islands', shortName: 'Heard Island' },
    { name: 'Vatican City State (Holy See)', shortName: 'Vatican' },
    { name: 'Iran, Islamic Republic of', shortName: 'Iran' },
    { name: 'Isle of Man', shortName: 'Isle of Man' },
    { name: 'North Korea', shortName: 'North Korea' },
    { name: 'South Korea', shortName: 'South Korea' },
    { name: 'Libyan Arab Jamahiriya', shortName: 'Libyan' },
    { name: 'Macedonia, The Former Yugoslav Republic of', shortName: 'Macedonia' },
    { name: 'Marshall Islands', shortName: 'Marshall Islands' },
    { name: 'Micronesia, Federated States of', shortName: 'Micronesia' },
    { name: 'Moldova, Republic of', shortName: 'Moldova' },
    { name: 'Netherlands Antilles', shortName: 'Netherlands' },
    { name: 'New Caledonia', shortName: 'New Caledonia' },
    { name: 'New Zealand', shortName: 'New Zealand' },
    { name: 'Norfolk Island', shortName: 'Norfolk Island' },
    { name: 'Northern Mariana Islands', shortName: 'Mariana Islands' },
    { name: 'Palestinian Territory, Occupied', shortName: 'Palestinian' },
    { name: 'Papua New Guinea', shortName: 'Papua' },
    { name: 'Puerto Rico', shortName: 'Puerto Rico' },
    { name: 'Saint Helena, Ascension and Tristan Da Cunha', shortName: 'Saint Helena' },
    { name: 'Saint Kitts and Nevis', shortName: 'Saint Kitts' },
    { name: 'Saint Lucia', shortName: 'Saint Lucia' },
    { name: 'Saint Pierre and Miquelon', shortName: 'Saint Pierre' },
    { name: 'Saint Vincent and the Grenadines', shortName: 'Saint Vincent' },
    { name: 'Sao Tome and Principe', shortName: 'Sao Tome' },
    { name: 'Saudi Arabia', shortName: 'Saudi Arabia' },
    { name: 'Sierra Leone', shortName: 'Sierra Leone' },
    { name: 'Solomon Islands', shortName: 'Solomon Islands' },
    { name: 'South Africa', shortName: 'South Africa' },
    { name: 'South Georgia and the South Sandwich Islands', shortName: 'South Georgia' },
    { name: 'Svalbard and Jan Mayen', shortName: 'Svalbard' },
    { name: 'Tanzania, United Republic of', shortName: 'Tanzania' },
    { name: 'Trinidad and Tobago', shortName: 'Trinidad' },
    { name: 'Turks and Caicos Islands', shortName: 'Caicos Islands' },
    { name: 'United Arab Emirates', shortName: 'UAE' },
    { name: 'United Kingdom', shortName: 'United Kingdom' },
    { name: 'United States', shortName: 'USA' },
    { name: 'United States Minor Outlying Islands', shortName: 'Outlying Islands' },
    { name: 'Venezuela, Bolivarian Republic of', shortName: 'Venezuela' },
    { name: 'Virgin Islands, British', shortName: 'Virgin Islands UK' },
    { name: 'Virgin Islands, U.S.', shortName: 'Virgin Islands US' },
    { name: 'Wallis and Futuna', shortName: 'Wallis' },
    { name: 'Western Sahara', shortName: 'Western Sahara' },
    { name: 'Democratic Republic of Congo', shortName: 'Democratic Congo' },
    { name: 'Republic of the Congo', shortName: 'Republic Congo' },
    { name: 'Federated States of Micronesia', shortName: 'Federated Micronesia' },
    { name: 'São Tomé and Príncipe', shortName: 'São Príncipe' },
]
export const getShortNameCountry = (name) => {
    const item = listCountry.find(x => x.name === name)
    if (item) {
        return item.shortName
    }
    return name;
}