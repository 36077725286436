import * as constants from './constant'
const INIT_STATE = {
    loading: false,
};
const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {        
        case constants.Cities:
        case constants.Cities_Get:
        case constants.Telegram_Get:
            return { ...state,  loading: true,sended:false };
        case constants.Cities_FAILED:
        case constants.Cities_Get_FAILED:
        case constants.Telegram_Get_FAILED:
            return { ...state, loading: false, error: action.payload };       
        case constants.Cities_SUCCESS:
            return { ...state, sended: true, loading: false, error: null };
        case constants.Cities_Get_SUCCESS:
            return { ...state, CitiesList: action.payload, loading: false, error: null };
        case constants.Telegram_Get_SUCCESS:
            return { ...state, TelegramInfo: action.payload, loading: false, error: null };
        default:
            return { ...state };
    }
};
export default Reducer;