import * as constants from './constant'

export const  ChatsGetList= (body) => ({
    type: constants.CHATS_Get_LIST,
    payload: { body },
});
export const ChatsGetListSuccess = (user) => ({
    type: constants.CHATS_Get_LIST_SUCCESS,
    payload: user,
});
export const ChatsGetListFailed = (error) => ({
    type: constants.CHATS_Get_LIST_FAILED,
    payload: error,
});


export const  ChatsGet= (body) => ({
    type: constants.CHATS_Get,
    payload: { body },
});
export const ChatsGetSuccess = (user) => ({
    type: constants.CHATS_Get_SUCCESS,
    payload: user,
});
export const ChatsGetFailed = (error) => ({
    type: constants.CHATS_Get_FAILED,
    payload: error,
});


export const  ChatsBlockUser= (body) => ({
    type: constants.CHATS_BLOCK_USER,
    payload: { body },
});
export const ChatsBlockUserSuccess = (user) => ({
    type: constants.CHATS_BLOCK_USER_SUCCESS,
    payload: user,
});
export const ChatsBlockUserFailed = (error) => ({
    type: constants.CHATS_BLOCK_USER_FAILED,
    payload: error,
});


export const  chatsDelete= (body) => ({
    type: constants.CHATS_DELETE,
    payload: { body },
});
export const chatsDeleteSuccess = (user) => ({
    type: constants.CHATS_DELETE_SUCCESS,
    payload: user,
});
export const chatsDeleteFailed = (error) => ({
    type: constants.CHATS_DELETE_FAILED,
    payload: error,
});
export const  chatsExistence= (body) => ({
    type: constants.CHATS_EXISTENCE,
    payload: { body },
});
export const chatsExistenceSuccess = (user) => ({
    type: constants.CHATS_EXISTENCE_SUCCESS,
    payload: user,
});
export const chatsExistenceFailed = (error) => ({
    type: constants.CHATS_EXISTENCE_FAILED,
    payload: error,
});


export const  ChatsHasNew= (body) => ({
    type: constants.CHATS_HAS_NEW,
    payload: { body },
});
export const ChatsHasNewSuccess = (user) => ({
    type: constants.CHATS_HAS_NEW_SUCCESS,
    payload: user,
});
export const ChatsHasNewFailed = (error) => ({
    type: constants.CHATS_HAS_NEW_FAILED,
    payload: error,
});
