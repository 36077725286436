import * as constants from './constant'

const INIT_STATE = {
    loading: false,
};

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case constants.Users_Get_NO_LOGIN:
        case constants.Users_UPDATE_INFO:
        case constants.Users_Get:
        case constants.Users_CITY_UPDATE_INFO:
        case constants.Users_NAME_UPDATE_INFO:
        case constants.Cities_Get_COUNTRY_CODE:
        case constants.Get_COUNTRY:
        case constants.Get_City:
        case constants.Update_State_User:
            return { ...state, loading: true, updateCity: false, updateName: false, updateCategories: false, updateLocation: false };
        case constants.Users_Get_NO_LOGIN_FAILED:
        case constants.Users_UPDATE_INFO_FAILED:
        case constants.Users_Get_FAILED:
        case constants.Users_CITY_UPDATE_INFO_FAILED:
        case constants.Users_NAME_UPDATE_INFO_FAILED:
        case constants.Cities_Get_COUNTRY_CODE_FAILED:
        case constants.Get_COUNTRY_FAILED:
        case constants.Get_City_FAILED:
            return { ...state, loading: false, error: action.payload };
        case constants.Update_Last_Update_SUCCESS:
            return { ...state, lastUpdate: action.payload.body, error: action.payload };
        case constants.Update_State_User_SUCCESS:
            if (state.ListUserNologin) {
                const oldList = state.ListUserNologin;
                const IndexUser = state.ListUserNologin.findIndex(x => x.id === action.payload.body.id)
                const newUser = { ...oldList[IndexUser], lastState: action.payload.body.state }
                const t = oldList.filter(x => x.id !== action.payload.body.id)
                t.push(newUser)
                return { ...state, ListUserNologin: t, loading: false, error: null };
            } else {
                return { ...state, loading: false, error: null  };
            }
        case constants.Users_CITY_UPDATE_INFO_SUCCESS:
            return { ...state, updateLocation: true, loading: false, error: null };
        case constants.Get_COUNTRY_SUCCESS:
            return { ...state, listCountry: action.payload.data, loading: false, error: null };
        case constants.Get_City_SUCCESS:
            return { ...state, listCity: action.payload.data.states, loading: false, error: null };
        case constants.Users_NAME_UPDATE_INFO_SUCCESS:
            return { ...state, updateName: true, loading: false, error: null };
        case constants.Users_Get_NO_LOGIN_SUCCESS:
            return { ...state, ListUserNologin: action.payload, loading: false, error: null };
        case constants.Users_Get_SUCCESS:
            return { ...state, InfoUser: action.payload, loading: false, error: null };
        case constants.Users_UPDATE_INFO_SUCCESS:
            return { ...state, IsUpdate: action.payload, loading: false, error: null };
        case constants.Cities_Get_COUNTRY_CODE_SUCCESS:
            return { ...state, CountryCode: action.payload, loading: false, error: null };

        default:
            return { ...state };
    }
};

export default Reducer;
