import { jwtDecode } from "jwt-decode";
import { getLoggedInUser } from './utils'
export const getActiveToken = () => {
    if (getLoggedInUser() !== null)
        return 'Bearer ' + getLoggedInUser();
    return null;
}
export const getValueToken = (parameter) => {
    try {
        const jwtToken = getLoggedInUser();
        if (!jwtToken) { return null; }
        const token = jwtDecode(jwtToken);
        return token[parameter];
    }
    catch {
        return null;
    }
}
export const getUserId = () => {
    try {
        const jwtToken = getLoggedInUser();
        if (!jwtToken) { return null; }
        const token = jwtDecode(jwtToken);
        return token.id;
    }
    catch {
        return null;
    }
}
export const HasData = () => {
    try {
        const jwtToken = getLoggedInUser();
        if (!jwtToken) { return false; }
        const token = jwtDecode(jwtToken);
       // const age = token.age;
        const name = token.name;
       // const des = token.des;

        // if (age !== null && name !== null && des !== null) {
        if (name !== null) {
            return true;
        }

        return false;
    }
    catch {
        return false;
    }
}
export const HasFullData = () => {
    try {
        const jwtToken = getLoggedInUser();
        if (!jwtToken) { return false; }
        const token = jwtDecode(jwtToken);
        const age = token.age;
        const name = token.name;
        const des = token.des;
        if (age !== null && name !== null && des !== null) {       
            return true;
        }

        return false;
    }
    catch {
        return false;
    }
}