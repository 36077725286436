import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionGet, optionPatch, optionPost } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';
import CountryCode from '../../assets/country-code.json'
function* GetCountryCode({ payload: { body } }) {
    try {
        yield put(action.CitiesGetCountryCodeSuccess(CountryCode));
    } catch (error) {
        let message = handleError(error);
        yield put(action.CitiesGetCountryCodeFailed(message));
    }
}

function* getListCity({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, `${listUrl.listCity}`, optionPost(body));
        yield put(action.GetCitySuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.GetCityFailed(message));
    }
}
function* getListCountry({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, `${listUrl.listCountry}`, optionGet(body));
        yield put(action.GetCountrySuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.GetCountryFailed(message));
    }
}
function* UsersNameUpdateInfo({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, `${listUrl.usersUpdateDescription}`, optionPatch(body));
        window.localStorage.setItem("strayers_token", response.data.access_token)
        yield put(action.UsersNameUpdateInfoSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersNameUpdateInfoFailed(message));
    }
}

function* UsersUpdateInfo({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, `${listUrl.usersUpdate}`, optionPatch(body));
        window.localStorage.setItem("strayers_token", response.data.access_token)
        yield put(action.UsersUpdateInfoSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersUpdateInfoFailed(message));
    }
}
function* UsersCityUpdateInfo({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.usersUpdateCity, optionPatch(body));
        window.localStorage.setItem("strayers_token", response.data.access_token)
        yield put(action.UsersCityUpdateInfoSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersCityUpdateInfoFailed(message));
    }
}
function* UsersGetNoLogin({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.users, optionGet(body));
        yield put(action.UsersGetNOLOGINSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UsersGetNOLOGINFailed(message));
    }
}

function* UsersGet({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.usersGet, optionGet(body));
        yield put(action.Users_GetSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Users_GetFailed(message));
    }
}
function* UsersUpdateState({ payload: { body } }) {
    try {
        yield put(action.UpdateStateUserSuccess(body));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UpdateStateUserFailed(message));
    }
}
function* UpdateLastUpdate({ payload: { body } }) {
   
    try {
        const date = new Date();
        yield put(action.UpdateLastUpdateSuccess({date}));
    } catch (error) {
        let message = handleError(error);
        yield put(action.UpdateLastUpdateFailed(message));
    }
}
export function* watchUpdateLastUpdate() {
    yield takeEvery(constants.Update_Last_Update, UpdateLastUpdate);
}
export function* watchUsersUpdateState() {
    yield takeEvery(constants.Update_State_User, UsersUpdateState);
}
export function* watchUsersNameUpdateInfo() {
    yield takeEvery(constants.Users_NAME_UPDATE_INFO, UsersNameUpdateInfo);
}

export function* watchUsersUpdateInfo() {
    yield takeEvery(constants.Users_UPDATE_INFO, UsersUpdateInfo);
}
export function* watchUsersCityUpdateInfo() {
    yield takeEvery(constants.Users_CITY_UPDATE_INFO, UsersCityUpdateInfo);
}
export function* watchUsersGet() {
    yield takeEvery(constants.Users_Get, UsersGet);
}
export function* watchUsersGetNoLogin() {
    yield takeEvery(constants.Users_Get_NO_LOGIN, UsersGetNoLogin);
}

export function* watchGetCountryCode() {
    yield takeEvery(constants.Cities_Get_COUNTRY_CODE, GetCountryCode);
}
export function* watchGetListCountry() {
    yield takeEvery(constants.Get_COUNTRY, getListCountry);
}
export function* watchGetListCity() {
    yield takeEvery(constants.Get_City, getListCity);
}

function* Saga() {
    yield all([
        fork(watchUpdateLastUpdate),
        fork(watchUsersUpdateState),
        fork(watchGetListCity),
        fork(watchGetListCountry),
        fork(watchUsersNameUpdateInfo),
        fork(watchUsersCityUpdateInfo),
        fork(watchUsersGet),
        fork(watchUsersUpdateInfo),
        fork(watchUsersGetNoLogin),
        fork(watchGetCountryCode),
    ]);
}
export default Saga;