import { all, call, fork, put, takeEvery} from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionGet, optionPost } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';
function* Cities_Add({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.cities, optionPost(body));        
        yield put(action.CitiesSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.CitiesFailed(message));
    }
}
function* Cities_Get({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.cities, optionGet(body));        
        yield put(action.CitiesGetSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.CitiesGetFailed(message));
    }
}
function* Telegram_Get({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.telegram, optionGet(body));     
        yield put(action.TelegramGetSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.TelegramGetFailed(message));
    }
}
export function* watchCities_Add() {
    yield takeEvery(constants.Cities, Cities_Add);
}
export function* watchCities_Get() {
    yield takeEvery(constants.Cities_Get, Cities_Get);
}
export function* watchTelegram_Get() {
    yield takeEvery(constants.Telegram_Get, Telegram_Get);
}
function* Saga() {
    yield all([
        fork(watchCities_Add),
        fork(watchCities_Get),
        fork(watchTelegram_Get),
    ]);
}
export default Saga;