import { all } from 'redux-saga/effects';
import Users from './Users/saga';
import Auth from './Auth/saga';
import Chats from './Chats/saga';
import ReportUsers from './ReportUsers/saga';
import Messages from './Messages/saga';
import Cities from './Cities/saga';

export default function* rootSaga(getState) {
    yield all([Cities(),Users(),Auth(),Messages(),Chats(),ReportUsers()]);
}