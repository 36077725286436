import * as constants from './constant'
const INIT_STATE = {
    loading: false,
};
const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {        
        case constants.Message_Send:
        case constants.MESSAGE_LIST_BY_CHAT_ID:
            return { ...state,  loading: true,sended:false };
        case constants.Message_Send_FAILED:
        case constants.MESSAGE_LIST_BY_CHAT_ID_FAILED:
            return { ...state, loading: false, error: action.payload };       
        case constants.Message_Send_SUCCESS:
            return { ...state, sended: true, loading: false, error: null };           
        case constants.MESSAGE_LIST_BY_CHAT_ID_SUCCESS:     
      //  console.log(action.payload.data.items)         
            return { ...state, listMessage:action.payload.data.items , loading: false, error: null };           
       case constants.ADD_MESSAGE_TO_LIST:
        if(state.listMessage){
            return {...state,listMessage: [...state.listMessage, action.payload]}
        }  
         return { ...state };        
        default:
            return { ...state };
    }
};
export default Reducer;
