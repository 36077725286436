import { all, call, fork, put, takeEvery} from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionPost } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';

function* AuthRegister({ payload: { body } }) {    
    try {
        const response = yield call(fetchJSON, listUrl.authRegister, optionPost(body));
        yield put(action.AuthRegisterSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.AuthRegisterFailed(message));
    }
}

function* AuthVerify({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.authVerify, optionPost(body));  
        window.localStorage.setItem("strayers_token", response.data.access_token)          
        yield put(action.AuthVerifySuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.AuthVerifyFailed(message));
    }
}
export function* watchAuthRegister() {
    yield takeEvery(constants.Auth_Register, AuthRegister);
}
export function* watchAuthVerify() {
    yield takeEvery(constants.Auth_Verify, AuthVerify);
}
function* Saga() {
    yield all([
        fork(watchAuthVerify),
        fork(watchAuthRegister),
    ]);
}
export default Saga;