import * as constants from './constant'

export const  AuthRegister= (body) => ({
    type: constants.Auth_Register,
    payload: { body },
});

export const AuthRegisterSuccess = (user) => ({
    type: constants.Auth_Register_SUCCESS,
    payload: user,
});

export const AuthRegisterFailed = (error) => ({
    type: constants.Auth_Register_FAILED,
    payload: error,
});


export const  AuthVerify= (body) => ({
    type: constants.Auth_Verify,
    payload: { body },
});

export const AuthVerifySuccess = (user) => ({
    type: constants.Auth_Verify_SUCCESS,
    payload: user,
});

export const AuthVerifyFailed = (error) => ({
    type: constants.Auth_Verify_FAILED,
    payload: error,
});



export const  Logout= (body) => ({
    type: constants.LOG_OUT_APP,
    payload: { body },
});

export const LogoutSuccess = (user) => ({
    type: constants.LOG_OUT_APP_SUCCESS,
    payload: user,
});

export const LogoutFailed = (error) => ({
    type: constants.LOG_OUT_APP_FAILED,
    payload: error,
});
