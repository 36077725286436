export const Cities = 'Cities';
export const Cities_SUCCESS = 'Cities_SUCCESS';
export const Cities_FAILED = 'Cities_FAILED';

export const Cities_Get = 'Cities_Get';
export const Cities_Get_SUCCESS = 'Cities_Get_SUCCESS';
export const Cities_Get_FAILED = 'Cities_Get_FAILED';

export const Telegram_Get = 'Telegram_Get';
export const Telegram_Get_SUCCESS = 'Telegram_Get_SUCCESS';
export const Telegram_Get_FAILED = 'Telegram_Get_FAILED';