import * as constants from './constant'

export const  UsersGetNOLOGIN= (body) => ({
    type: constants.Users_Get_NO_LOGIN,
    payload: { body },
});

export const UsersGetNOLOGINSuccess = (user) => ({
    type: constants.Users_Get_NO_LOGIN_SUCCESS,
    payload: user,
});

export const UsersGetNOLOGINFailed = (error) => ({
    type: constants.Users_Get_NO_LOGIN_FAILED,
    payload: error,
});



export const  UsersUpdateInfo= (body) => ({
    type: constants.Users_UPDATE_INFO,
    payload: { body },
});

export const UsersUpdateInfoSuccess = (user) => ({
    type: constants.Users_UPDATE_INFO_SUCCESS,
    payload: user,
});

export const UsersUpdateInfoFailed = (error) => ({
    type: constants.Users_UPDATE_INFO_FAILED,
    payload: error,
});
export const  UsersCityUpdateInfo= (body) => ({
    type: constants.Users_CITY_UPDATE_INFO,
    payload: { body },
});

export const UsersCityUpdateInfoSuccess = (user) => ({
    type: constants.Users_CITY_UPDATE_INFO_SUCCESS,
    payload: user,
});

export const UsersCityUpdateInfoFailed = (error) => ({
    type: constants.Users_CITY_UPDATE_INFO_FAILED,
    payload: error,
});


export const  Users_Get= (body) => ({
    type: constants.Users_Get,
    payload: { body },
});

export const Users_GetSuccess = (user) => ({
    type: constants.Users_Get_SUCCESS,
    payload: user,
});

export const Users_GetFailed = (error) => ({
    type: constants.Users_Get_FAILED,
    payload: error,
});

export const  UsersNameUpdateInfo= (body) => ({
    type: constants.Users_NAME_UPDATE_INFO,
    payload: { body },
});

export const UsersNameUpdateInfoSuccess = (user) => ({
    type: constants.Users_NAME_UPDATE_INFO_SUCCESS,
    payload: user,
});

export const UsersNameUpdateInfoFailed = (error) => ({
    type: constants.Users_NAME_UPDATE_INFO_FAILED,
    payload: error,
});

export const  CitiesGetCountryCode= (body) => ({
    type: constants.Cities_Get_COUNTRY_CODE,
    payload: { body },
});

export const CitiesGetCountryCodeSuccess = (user) => ({
    type: constants.Cities_Get_COUNTRY_CODE_SUCCESS,
    payload: user,
});

export const CitiesGetCountryCodeFailed = (error) => ({
    type: constants.Cities_Get_COUNTRY_CODE_FAILED,
    payload: error,
});


export const  GetCountry= (body) => ({
    type: constants.Get_COUNTRY,
    payload: { body },
});

export const GetCountrySuccess = (user) => ({
    type: constants.Get_COUNTRY_SUCCESS,
    payload: user,
});

export const GetCountryFailed = (error) => ({
    type: constants.Get_COUNTRY_FAILED,
    payload: error,
});

export const  GetCity= (body) => ({
    type: constants.Get_City,
    payload: { body },
});

export const GetCitySuccess = (user) => ({
    type: constants.Get_City_SUCCESS,
    payload: user,
});

export const GetCityFailed = (error) => ({
    type: constants.Get_City_FAILED,
    payload: error,
});

export const  UpdateLastUpdate= (body) => ({
    type: constants.Update_Last_Update,
    payload: { body },
});
export const  UpdateLastUpdateSuccess= (body) => ({
    type: constants.Update_Last_Update_SUCCESS,
    payload: { body },
});
export const  UpdateLastUpdateFailed= (body) => ({
    type: constants.Update_Last_Update_FAILED,
    payload: { body },
});


export const  UpdateStateUser= (body) => ({
    type: constants.Update_State_User,
    payload: { body },
});
export const  UpdateStateUserSuccess= (body) => ({
    type: constants.Update_State_User_SUCCESS,
    payload: { body },
});
export const  UpdateStateUserFailed= (body) => ({
    type: constants.Update_State_User_FAILED,
    payload: { body },
});