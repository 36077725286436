import { combineReducers } from 'redux';
import Users from './Users/reducers';
import Auth from './Auth/reducers';
import Chats from './Chats/reducers';
import ReportUsers from './ReportUsers/reducers';
import Messages from './Messages/reducers';
import Cities from './Cities/reducer';


export default combineReducers({
   Cities, Users,Auth,Chats,ReportUsers,Messages,
});