export const Users_Get_NO_LOGIN = 'Users_Get_NO_LOGIN';
export const Users_Get_NO_LOGIN_SUCCESS = 'Users_Get_NO_LOGIN_SUCCESS';
export const Users_Get_NO_LOGIN_FAILED = 'Users_Get_NO_LOGIN_FAILED';

export const Users_UPDATE_INFO = 'Users_UPDATE_INFO';
export const Users_UPDATE_INFO_SUCCESS = 'Users_UPDATE_INFO_SUCCESS';
export const Users_UPDATE_INFO_FAILED = 'Users_UPDATE_INFO_FAILED';


export const Users_CITY_UPDATE_INFO = 'Users_CITY_UPDATE_INFO';
export const Users_CITY_UPDATE_INFO_SUCCESS = 'Users_CITY_UPDATE_INFO_SUCCESS';
export const Users_CITY_UPDATE_INFO_FAILED = 'Users_CITY_UPDATE_INFO_FAILED';

export const Users_Get = 'Users_Get';
export const Users_Get_SUCCESS = 'Users_Get_SUCCESS';
export const Users_Get_FAILED = 'Users_Get_FAILED';

export const Users_NAME_UPDATE_INFO = 'Users_NAME_UPDATE_INFO';
export const Users_NAME_UPDATE_INFO_SUCCESS = 'Users_NAME_UPDATE_INFO_SUCCESS';
export const Users_NAME_UPDATE_INFO_FAILED = 'Users_NAME_UPDATE_INFO_FAILED';

export const Cities_Get_COUNTRY_CODE = 'Cities_Get_COUNTRY_CODE';
export const Cities_Get_COUNTRY_CODE_SUCCESS = 'Cities_Get_COUNTRY_CODE_SUCCESS';
export const Cities_Get_COUNTRY_CODE_FAILED = 'Cities_Get_COUNTRY_CODE_FAILED';


export const Get_COUNTRY = 'Get_COUNTRY';
export const Get_COUNTRY_SUCCESS = 'Get_COUNTRY_SUCCESS';
export const Get_COUNTRY_FAILED = 'Get_COUNTRY_FAILED';

export const Get_City = 'Get_City';
export const Get_City_SUCCESS = 'Get_City_SUCCESS';
export const Get_City_FAILED = 'Get_City_FAILED';


export const Update_State_User = 'Update_State_User';
export const Update_State_User_SUCCESS = 'Update_State_User_SUCCESS';
export const Update_State_User_FAILED = 'Update_State_User_FAILED';

export const Update_Last_Update = 'Update_Last_Update';
export const Update_Last_Update_SUCCESS = 'Update_Last_Update_SUCCESS';
export const Update_Last_Update_FAILED = 'Update_Last_Update_FAILED';