const users='/users'
const auth='/auth'
const chats='/chats'
const reportUsers='/reportUsers'
const messages='/messages'
const cities='/cities'
const telegram='/telegram'
export const listUrl = {    
    usersGetNologin: `${users}/nologin`,
    usersUpdate: `${users}/update`,
    usersUpdateCity: `${users}/updateLocation`,
    usersUpdateDescription: `${users}/updateDescription`,
    usersGet: `${users}/Get`,
    users: `${users}`,     
    cities: `${cities}`,  
    telegram: `${telegram}`, 
      
    authRegister: `${auth}/register`,
    authVerify: `${auth}/verify`,  
    chatsChatList: `${chats}/list`,
    chatsGet: `${chats}/GetById`,
    chatsDelete: `${chats}/Delete`,   
    ChatExistence: `${chats}/ChatExistence`,
    ChatBlockUser: `${chats}/BlockUser`, 
    ChatHasNewChat: `${chats}/hasNewChat`, 
    reportUsers: `${reportUsers}`,
    messages: `${messages}/Add`,   
    messagesListByChatId: `${messages}/listByChatId`,
    listCountry:'https://countriesnow.space/api/v0.1/countries/positions',
    listCity:'https://countriesnow.space/api/v0.1/countries/states'
}