export const Auth_Register = 'Auth_Register';
export const Auth_Register_SUCCESS = 'Auth_Register_SUCCESS';
export const Auth_Register_FAILED = 'Auth_Register_FAILED';

export const Auth_Verify = 'Auth_Verify';
export const Auth_Verify_SUCCESS = 'Auth_Verify_SUCCESS';
export const Auth_Verify_FAILED = 'Auth_Verify_FAILED';


export const LOG_OUT_APP = 'LOG_OUT_APP';
export const LOG_OUT_APP_SUCCESS = 'LOG_OUT_APP_SUCCESS';
export const LOG_OUT_APP_FAILED = 'LOG_OUT_APP_FAILED';
