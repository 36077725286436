import React from 'react';
import { BrowserRouter,  Route, Switch } from 'react-router-dom';
import { allFlattenRoutes as Routers } from './index';
import ErrorBoundaryComponent from './ErrorBoundary'
import { ErrorBoundary } from "react-error-boundary";
import { getLayout } from '../layouts/LayoutContainers';
const Routes = (props) => {
    const Layout = getLayout();   
    const LogSave = (error) => {       
        console.log(error);
    };
    return <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorBoundaryComponent} onError={(e) => LogSave(e)}>
            <Layout {...props}>
                <Switch>
                    {Routers.map((route, index) => {
                        return (<Route key={`B${index}`} exact={route.exact} path={route.path} component={route.component} />)
                    })}                   
                </Switch>
            </Layout>
        </ErrorBoundary>
    </BrowserRouter>
}
export default Routes
