import * as constants from './constant'

export const  Cities= (body) => ({
    type: constants.Cities,
    payload: { body },
});

export const CitiesSuccess = (user) => ({
    type: constants.Cities_SUCCESS,
    payload: user,
});

export const CitiesFailed = (error) => ({
    type: constants.Cities_FAILED,
    payload: error,
});
export const  CitiesGet= (body) => ({
    type: constants.Cities_Get,
    payload: { body },
});

export const CitiesGetSuccess = (user) => ({
    type: constants.Cities_Get_SUCCESS,
    payload: user,
});

export const CitiesGetFailed = (error) => ({
    type: constants.Cities_Get_FAILED,
    payload: error,
});
export const TelegramGet= (body) => ({
    type: constants.Telegram_Get,
    payload: { body },
});

export const TelegramGetSuccess = (user) => ({
    type: constants.Telegram_Get_SUCCESS,
    payload: user,
});

export const TelegramGetFailed = (error) => ({
    type: constants.Telegram_Get_FAILED,
    payload: error,
});