import { all, call, fork, put, takeEvery} from 'redux-saga/effects';
import * as constants from './constant'
import * as action from './action'
import { fetchJSON, optionGet, optionPost } from '../../ApiUtils/apiUtils';
import { handleError } from '../../ApiUtils/handleError';
import { listUrl } from '../../ApiUtils/listUrl';
function* Message_Send({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.messages, optionPost(body));        
        yield put(action.Message_SendSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Message_SendFailed(message));
    }
}
function* Message_listByChatId({ payload: { body } }) {
    try {
        const response = yield call(fetchJSON, listUrl.messagesListByChatId, optionGet(body));        
        yield put(action.Message_ListByChatIdSuccess(response));
    } catch (error) {
        let message = handleError(error);
        yield put(action.Message_ListByChatIdFailed(message));
    }
}
export function* watchMessage_Send() {
    yield takeEvery(constants.Message_Send, Message_Send);
}
export function* watchMessage_listByChatId() {
    yield takeEvery(constants.MESSAGE_LIST_BY_CHAT_ID, Message_listByChatId);
}
function* Saga() {
    yield all([
        fork(watchMessage_listByChatId),
        fork(watchMessage_Send),
    ]);
}
export default Saga;